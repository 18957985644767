<template>
  <el-card class="">
    <div slot="header" class="clearfix">
      <el-button size="mini" icon="el-icon-arrow-left" circle style="margin-right:5px" @click="goback()"></el-button>
      <span>{{title}}</span>
    </div>
    <div class="content">
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px">
        <el-form-item label="名称" prop="lessonName">
          <el-input clearable v-model="form.lessonName" prefix-icon="el-icon-discount"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input clearable v-model="form.title" prefix-icon="el-icon-document"></el-input>
        </el-form-item>
        <el-form-item label="背景图片" prop="backgroundImage">
          <el-input clearable v-model="form.backgroundImage" prefix-icon="el-icon-document"></el-input>
          <div class="btn_group">
            <uploadImage style="float: left;" v-model="form.backgroundImage"></uploadImage>
          </div>
        </el-form-item>
        <el-form-item label="链接" prop="attachment">
          <el-input clearable v-model="form.attachment" type="textarea" :autosize="{ minRows: 2}" style="resize: none;"
            disabled>
          </el-input>
          <el-input clearable v-model="url" style="padding:5px 0" prefix-icon="el-icon-link"></el-input>
          <el-button type="primary" @click="addUrl()" :disabled="(url == null || !url.length)" icon="el-icon-circle-plus-outline">
            添加URL</el-button>
          <el-button @click="revoke()" :disabled="form.attachment == null || !form.attachment.length">撤销</el-button>
        </el-form-item>
        <el-form-item label="详情描述" prop="notes" class="form_item">
          <quill-editor ref="text" v-model="form.notes" :options="editorOption" class="ql-editor" />
        </el-form-item> 
        <div class="btn_group">
          <el-button type="primary" style="float: left;" @click="handleSendCourseUpdateMessage()"
                icon="el-icon-bell">课程更新通知</el-button>
          <el-button type="primary" @click="lessonSubmit()" :disabled="!canSubmit" icon="el-icon-circle-check">保存草稿
          </el-button>
          <el-button type="success" @click="lessonPublish()" :disabled="!canSubmit" icon="el-icon-circle-check">发布
          </el-button>
        </div>
      </el-form>
    </div>
  </el-card>
</template>

<script>
  import {
    createNamespacedHelpers
  } from 'vuex'
  const courses = createNamespacedHelpers('courses')
  const subscribeMessage = createNamespacedHelpers('subscribeMessage')
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import {
    quillEditor
  } from 'vue-quill-editor'
  import uploadImage from './UploadImage'

  export default {
    components: {
      quillEditor,
      uploadImage
    },
    data() {
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('详情描述不能为空'))
        }
      }
      var checkAttachment = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('链接不能为空'))
        }
      }
      var checkLessonName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('名称不能为空'))
        }
      }
      var checkTitle = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('标题不能为空'))
        }
      }
      return {
        formValue: false,
        form: {
          lessonName: '',
          title: '',
          notes: '',
          attachment: '',
          backgroundImage: '',
        },
        rules: {
          lessonName: [{
            validator: checkLessonName,
            trigger: 'blur'
          }],
          title: [{
            validator: checkTitle,
            trigger: 'blur'
          }],
          notes: [{
            validator: checkDescription,
            trigger: 'blur'
          }],
        },
        isEdit: false,
        editorOption: {
          placeholder: "请在这里输入",
        },
        url: '',
      }
    },
    computed: {
      title() {
        return this.isEdit ? '修改课时-录入信息' : '添加课时-录入信息'
      },
      canSubmit() {
        return this.form.lessonName && this.form.title
      }
    },
    methods: {
      ...courses.mapActions(['addLessons', 'editLessons', 'publishLessons']),
      ...subscribeMessage.mapActions(['sendCourseUpdateMessage']),
      goback() {
        this.$router.push({
          name: 'CoursesManagement',
          params: {
            name: this.$route.params.courseId,
            data: this.$route.params.courseData,
          }
        })
      },
      addUrl() {
        this.form.attachment = this.form.attachment.length ? `${this.form.attachment};${this.url}` : `${this.url}`
        this.url = ''
      },
      revoke() {
        let index = this.form.attachment.lastIndexOf(';')
        if (index >= 0) {
          this.form.attachment = this.form.attachment.substring(0, index)
        } else {
          this.form.attachment = ''
        }
      },
      clearAll() {
        this.isEdit = false
        this.form = {
          lessonName: '',
          title: '',
          notes: '',
          attachment: '',
          backgroundImage: ''
        }
        this.url = ''
      },
      lessonSubmit() {
        Object.prototype.hasOwnProperty.call(this.form, 'id') ? this.submitEditLesson() : this.submitAddLesson()
      },
      lessonPublish() {
        this.form.courseId = this.$route.params.courseId
        this.publishLessons(this.form).then(() => {
          this.$message({
            type: 'success',
            message: '发布成功'
          })
          //this.goback()
        }).catch(() => {
          this.$message.error('发布失败')
        })
      }, 
      submitAddLesson() {
        let courseId = this.$route.params.courseId
        this.addLessons({
          ...this.form,
          courseId
        }).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          //this.goback()
        }).catch(() => {
          this.$message.error('添加失败')
        })
      },
      submitEditLesson() {
        this.editLessons(this.form).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          //this.goback()
        }).catch(() => {
          this.$message.error('修改失败')
        })
      },
      handleSendCourseUpdateMessage() {
        this.sendCourseUpdateMessage(this.$route.params.courseId).then(() => {
          this.$message({
            type: 'success',
            message: '发送成功'
          })
        }).catch(() => {
          this.$message.error('发送失败')
        })
      },
    },
    beforeCreate() {
      if (!this.$route.params.courseId) {
        this.$router.push({
          path: '/'
        })
      }
    },
    mounted() {
      if (this.$route.params.lessonData) {
        this.form = this.$route.params.lessonData
      }
    },
  }
</script>

<style scoped>
  .btn_group {
    padding: 10px 0;
    text-align: right;
    box-sizing: border-box;
  }

  .form_item>>>.ql-container {
    height: 500px !important;
  }
</style>